import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getPurhcasesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';

type Gift = {
  accountEmail: string;
  productId: string;
  expirationDate: string;
};

export const useAddGift = () => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (gift: Gift) =>
      BillingHttpClient.post<void>(
        app,
        '/api/v1/purchase/report',
        {
          app,
          email: gift.accountEmail,
          purchase_type: 'gift',
          store_product_id: gift.productId,
          platform_data: {
            order_id: gift.accountEmail,
            expiration_date: new Date(gift.expirationDate).toISOString(),
          },
        },
        {
          headers: {
            'X-Account-Email': gift.accountEmail,
            'X-Syncai-App-Id': app,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getPurhcasesQueryKey(app),
      });
    },
  });
};
